import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import "../assets/iolar.css"

const RecomendIolar = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "preporuka" })}
        canonicalUrl={"recomend-iolar"}
      />
      <div className="iolar">
        <StaticImage
          className="iolar-img"
          src="../images/recomend/IOLAR_RECOMMONDATION.jpg"
          alt="Iolar recomendation"
          layout="constrained"
          placeholder="blurred"
        />
      </div>
    </Layout>
  )
}

export default RecomendIolar
